import { Transporter } from "./transporter.interface";

// job creation or updation interface
export interface Job {
  jobId?: string;
  fedId?: number;
  userId?: number;
  transporterJobHandlingRequests: TransporterJobHandlingRequest[];
  jobDescription: string;
  jobType: string;
  jobCode: string;
  service: string;
  vesselName: string;
  voyageVia: string;
  voa: string;
  cycle: string;
  gateOpenDate: string;
  gateCloseDate: string;
  source: string;
  destination: string;
  sourceLatitude: string;
  sourceLongitude: string;
  destLatitude: string;
  destLongitude: string;
  createdDate?: string;
}

export interface TransporterJobHandlingRequest {
  transporterId: number;
  countTypeSizeRequestList: CountTypeSizeRequestList[];
}

export interface CountTypeSizeRequestList {
  count: number;
  containerType: string;
  containerSize: string;
}

// job order interface fetched from server
export interface JobOrder {
  jobId: string;
  fedId: number;
  createdByUserId: number;
  updatedByUserId: any;
  jobDescription: string;
  isFinal: boolean;
  etaInSeconds: number;
  tripEstimate: TripEstimate;
  cycle: string;
  jobType: string;
  jobCode: string;
  jobStatus: string;
  service: string;
  voa: string;
  vesselName: string;
  voyageVia: string;
  gateOpenDate: number;
  gateCloseDate: number;
  source: string;
  destination: string;
  sourceLatitude: string;
  sourceLongitude: string;
  destLatitude: string;
  destLongitude: string;
  jobBreakups: JobBreakup[];
  createdDate: string;
  links?: [];
  startDate?: number;
  endDate?: number;
  availableToDrivers?: boolean;
}

export interface EnrichedJobOrder extends JobOrder {
  transporterDetails?: string;
  containerCount?: number;
}

export interface TripEstimate {
  destinationAddress: string;
  originAddress: string;
  distanceInMts: number;
  durationInSecs: number;
  durationString: string;
  durationWithTrafficInSec: number;
  durationWithTrafficString: string;
}

export interface JobBreakup {
  jobBreakupId: number;
  createdByUserId: number;
  updatedByUserId: any;
  count: number;
  containerType: string;
  containerSize: string;
  subJobEtaInSeconds: any;
  jobBreakUpDetails: JobBreakUpDetail[];
}

export interface JobBreakUpDetail {
  jobBreakupDetailId: number;
  transporterIdName: Transporter;
  truckIdName: any;
  driverIdName: any;
  singleTripEtaInSeconds: any;
  createdByUserId: number;
  updatedByUserId: any;
  containerNumber: any;
  containerType: string;
  containerSize: string;
  startDate: any;
  endDate: any;
  createdDate: any;
  updatedDate: any;
  isDoubleTwenty?: boolean;
  doubleTwentyDetails?: {
    jobBreakupDetailId: number;
    containerNumber: string;
    containerType: string;
    containerSize: string;
  }[];
}

export interface TripContainer {
  tripId: number;
  containerNumber: string;
}

export interface ContainerStats {
  totalContainer: number;
  totalAssigned: number;
  pendingToAssign: number;
  totalDroppedContainers: number;
  totalJobStatusPercentage: number;
}

export interface TripHistoryStats {
  trips: number;
  containers: number;
  count20: number;
  count40: number;
  count45: number;
}

export const enum JobStatusEnum {
  INITIATED = "INITIATED",
  ASSIGNED = "ASSIGNED",
  STARTED = "STARTED",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}
