import React, { useEffect, useState } from "react";
import "./ProfilePage.scss";
import { ReactComponent as Avatar } from "../../assets/images/avatar.svg";
import backgroundimage from "../../assets/images/profile-bkg.jpeg";
import EditProfileModal from "./EditProfileModal";
import EditCompanyModal from "./EditCompanyModal";
import NotificationPanel from "../Notification/NotificationPanel";

const ProfilePage: React.FC = () => {
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isContainerModalOpen, setIsContainerModalOpen] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    fullName: "",
    role: "",
    email: "",
    phone: "",
    password: "",
  });
  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    gstNo: "",
    address: "",
  });

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "production") {
      const profileInfo = {
        fullName: "Amir Pathan",
        role: "Federation Admin",
        email: "operation@cnbtek.com",
        phone: "9561281481",
        password: "password",
      };
      setProfileInfo(profileInfo);
      const companyInfo = {
        companyName: "CNB Integrated Logistics Solutions Pvt. Ltd.",
        gstNo: "27AAQCM9464R1Z5",
        address:
          "EMPEZAR GLOBAL CHIRLE, BELONDAKR, CHIRLE POST URAN, RAIGAD, MAHARASHTRA - 411035",
      };
      setCompanyInfo(companyInfo);
    } else {
      const profileInfo = {
        fullName: "Demo Fed",
        role: "Federation Admin",
        email: "demofed@cnbtek.com",
        phone: "xxxxxxxxxx",
        password: "password",
      };
      setProfileInfo(profileInfo);
      const companyInfo = {
        companyName: "CNB Integrated Logistics Solutions Pvt. Ltd.",
        gstNo: "27AAQCM9464R1Z5",
        address:
          "Enzyme Tech Park #1604, Ground Floor, 25th Main, 22nd Cross Rd, Sector 2, HSR Layout, Bengaluru, Karnataka - 560102",
      };
      setCompanyInfo(companyInfo);
    }
  }, []);

  return (
    <div className="profile-page p-[20px]">
      {/* <NotificationPanel /> */}
      <div className="profile-card">
        <div className="card-top">
          <img
            src={backgroundimage}
            alt="Background"
            className="background-image"
          />
          <div className="avatar">
            <Avatar className="avatar-image" />
          </div>
        </div>

        <div className="containerbox">
          {/* Personal Information Section */}
          <div className="container personal-info">
            <div className="header-row">
              <h2>Personal Information</h2>
              {/* <button
                className="edit-button"
                onClick={() => setIsProfileModalOpen(true)}
              >
                Edit
              </button> */}
            </div>
            <div className="flex-row">
              <div className="flex-col half-width">
                <div className="label">Full Name:</div>
                <div className="value">{profileInfo.fullName}</div>
              </div>
              <div className="flex-col half-width">
                <div className="label">Role:</div>
                <div className="value">{profileInfo.role}</div>
              </div>
            </div>
            <div className="flex-row">
              <div className="flex-col third-width">
                <div className="label">Email:</div>
                <div className="value">{profileInfo.email}</div>
              </div>
              <div className="flex-col third-width">
                <div className="label">Phone:</div>
                <div className="value">+91 {profileInfo.phone}</div>
              </div>
              <div className="flex-col third-width">
                <div className="label">Password:</div>
                <div className="value">
                  {"*".repeat(profileInfo.password.length)}
                </div>
              </div>
            </div>
          </div>

          {/* Container Information Section */}
          <div className="container container-info">
            <div className="header-row">
              <h2>Company Information</h2>
              {/* <button
                className="edit-button"
                onClick={() => setIsContainerModalOpen(true)}
              >
                Edit
              </button> */}
            </div>
            <div className="flex-row">
              <div className="flex-col half-width">
                <div className="label">Company Name:</div>
                <div className="value">{companyInfo.companyName}</div>
              </div>
              <div className="flex-col half-width">
                <div className="label">GST No.:</div>
                <div className="value">{companyInfo.gstNo}</div>
              </div>
            </div>
            <div className="flex-row">
              <div className="flex-col third-width">
                <div className="label">Address:</div>
                <div className="value">{companyInfo.address}</div>
              </div>
            </div>
          </div>

          {/* Modals for Editing */}
          {/* <EditProfileModal
            isOpen={isProfileModalOpen}
            onClose={() => setIsProfileModalOpen(false)}
            initialData={profileInfo}
          />

          <EditCompanyModal
            isOpen={isContainerModalOpen}
            onClose={() => setIsContainerModalOpen(false)}
            initialData={companyinfo}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
