import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";

const PublicRoute: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? (
    <Navigate to="/jobs/all" replace />
  ) : (
    <Outlet /> // Render the child components (like SignIn, Signup, Forgot Password and Reset Password)
  );
};

export default PublicRoute;
