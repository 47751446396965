import "./SignIn.scss";
import React from "react";
import { showToast } from "../../components/Toaster";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import logo from "../../assets/st-logo.svg";
import { useLoading } from "../../contexts/LoadingContext";

const baseURL = process.env.REACT_APP_BASE_URL;

interface FormValues {
  email: string;
  password: string;
}

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Password must be at least 3 characters")
    .required("Password is required"),
});

const SignIn: React.FC = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const loader = useLoading();

  const initialValues: FormValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values: FormValues) => {
    loader.setLoading(true);
    try {
      const url = baseURL + "/common/login";
      const response = await axios(url, {
        method: "POST",
        data: {
          email: values.email,
          password: values.password,
        },
      });
      // showToast("Login Successful !", "success");
      const authToken = response.headers["authorization"];
      const refreshToken = response.headers["refresh-token"];
      login(authToken, refreshToken);
      navigate("/jobs/all", { replace: true });
      setTimeout(() => {
        loader.setLoading(false);
      }, 1000);
    } catch (err) {
      console.error("Error:", err);
      setTimeout(() => {
        loader.setLoading(false);
        showToast("Incorrect email or password ! Please try again", "error");
      }, 300);
    }
  };

  return (
    <div className="login-page flex items-center justify-center h-screen ambient-bkg">
      <div className="card-container flex flex-col items-center justify-center">
        <div className="logo-container">
          <img src={logo} alt="Smart Transport Logo" />
        </div>
        <div className="form-container">
          <div className="header-cover text_align_center">
            <div className="header-text">Sign In</div>
            <div className="sub-header-text">Please fill your details</div>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form>
                <div className="form-group">
                  <div className="form-field">
                    <label htmlFor="email" className="required_field_label">
                      Email
                    </label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      autoComplete="email"
                      className={
                        touched.email && errors.email ? "invalid_field" : ""
                      }
                    />
                  </div>
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="validation_error"
                  />
                </div>

                {/* no-mb */}
                <div className="form-group ">
                  <div className="form-field">
                    <label htmlFor="password" className="required_field_label">
                      Password
                    </label>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Enter your password"
                      className={
                        touched.password && errors.password
                          ? "invalid_field"
                          : ""
                      }
                    />
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="validation_error"
                  />
                </div>

                {/* <div className="email_login_options flex float-end">
                  <a href="/forgot-password">Forgot Password?</a>
                </div> */}

                <button
                  className="primary_button mt-1"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Sign In
                </button>
              </Form>
            )}
          </Formik>

          {/* <div className="footer_msg">
            Don't have an account? <a href="/sign-up">Sign Up</a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
