import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileButton from "../ProfileButton/ProfileButton";
import { getPageTitle, getSubPageTitle } from "../../utils/dynamicPageHeader";
import { ReactComponent as Backbutton } from "../../assets/icons/back-button.svg";
import { ReactComponent as NotificationsIcon } from "../../assets/icons/Notification.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/add-icon.svg";
import "./Header.scss";
import SearchBar from "../SearchBar/SearchBar";
import NotificationPanel from "../Notification/NotificationPanel";

interface HeaderProps {
  searchQuery?: string;
  onSearchQueryChange: (searchQuery: string) => void;
}

const Header: React.FC<HeaderProps> = ({
  searchQuery,
  onSearchQueryChange,
}) => {
  const location = useLocation();
  const currentPage = location.pathname.substring(0);
  const pageTitle = getPageTitle(currentPage);
  const showJobsSearch = [
    "/jobs/all",
    "/jobs/initiated",
    "/jobs/assigned",
    "/jobs/in-progress",
    "/jobs/completed",
    "/jobs/cancelled",
  ].includes(currentPage);
  const navigate = useNavigate();
  const subHeaderPathNames = ["/assign-containers"];
  const showSubPageHeader = subHeaderPathNames.some(
    (subHeaderPathName: string) => currentPage.includes(subHeaderPathName)
  );
  const subPagetitle = getSubPageTitle(currentPage);

  return (
    <div className="page-header ">
      <div className="left-section flex items-center">
        {!showSubPageHeader && (
          <div className="header-name px-[10px]">{pageTitle}</div>
        )}
        {showSubPageHeader && (
          <>
            <Backbutton
              className="back-button cursor-pointer text-[#A3A8B4]"
              onClick={() => navigate(-1)}
            ></Backbutton>
            <div className="header-name px-[10px]">{subPagetitle}</div>
          </>
        )}
      </div>

      {/* {showJobsSearch && (
        <div className="center-section">
          <SearchBar
            onSearch={onSearchQueryChange}
            showClearButton={true}
            placeholder="Search by Job code or Container number"
          ></SearchBar>
        </div>
      )} */}

      <div className="right-section flex items-center">
        {/* <NotificationsIcon></NotificationsIcon> */}
        {showJobsSearch && (
          <button
            type="button"
            onClick={() => navigate("/jobs/create-new")}
            className="primary_button min-w-[150px]"
          >
            <div className="flex gap-2">
              <AddIcon />
              Create New
            </div>
          </button>
        )}
        {/* <NotificationPanel /> */}
        <ProfileButton></ProfileButton>
      </div>
    </div>
  );
};

export default Header;
