import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

interface DateTimePickerComponentProps {
  label?: string;
  value?: Date | null;
  onChange?: (newValue: Date | null) => void;
  className?: string;
  onInputClicked?: () => void;
  onBlurred?: () => void;
  autoSelectDateOption?: "today" | "tomorrow" | null;
  skipMinDate?: boolean;
}

const DateTimePickerComponent = forwardRef<
  { reset: () => void },
  DateTimePickerComponentProps
>(
  (
    {
      label = "Select Date and Time",
      value,
      onChange,
      className,
      onInputClicked,
      onBlurred,
      autoSelectDateOption = null,
      skipMinDate = false,
    },
    ref
  ) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(
      value || null
    );

    useEffect(() => {
      if (autoSelectDateOption && !value) {
        const currentDate = new Date();
        if (autoSelectDateOption === "today") {
          setSelectedDate(currentDate); // Set to today
          if (onChange) onChange(currentDate);
        } else if (autoSelectDateOption === "tomorrow") {
          const tomorrow = new Date(currentDate);
          tomorrow.setDate(currentDate.getDate() + 1); // Increment date by 1
          setSelectedDate(tomorrow); // Set to tomorrow
          if (onChange) onChange(tomorrow);
        }
      } else if (value) {
        setSelectedDate(value); // Set to provided value
      }
    }, [autoSelectDateOption, value]);

    const currentDate = new Date();

    // Expose reset function to parent
    useImperativeHandle(ref, () => ({
      reset() {
        if (autoSelectDateOption === "today") {
          const today = new Date();
          setSelectedDate(today);
          if (onChange) onChange(today);
        } else if (autoSelectDateOption === "tomorrow") {
          const tomorrow = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1);
          setSelectedDate(tomorrow);
          if (onChange) onChange(tomorrow);
        } else {
          setSelectedDate(value || null);
        }
      },
    }));

    const handleDateChange = (newValue: Date | null) => {
      setTimeout(() => {
        setSelectedDate(newValue);
        if (onChange) onChange(newValue);
      }, 150);
    };

    const handleInputBlur = () => {
      setTimeout(() => {
        if (onBlurred) onBlurred();
      }, 150);
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          onClose={handleInputBlur}
          minDate={skipMinDate ? undefined : currentDate} // Conditionally skip minDate
          // minTime={currentDate}
          format="dd/MMM/yyyy hh:mm aa"
          className={className}
          label={label}
          value={selectedDate}
          onChange={handleDateChange}
          // slots={{ inputAdornment: CalendarIcon }}
          slotProps={{
            // inputAdornment: {
            // position: "start",
            // },
            // openPickerIcon: CalendarIcon,
            toolbar: {
              hidden: true,
              // hidden: hideOkButton,
            },
            textField: {
              InputProps: {
                placeholder: `${label}`,
              },
              role: "button",
              onClick: onInputClicked,
              onBlur: handleInputBlur,
            },
          }}
        />
      </LocalizationProvider>
    );
  }
);

export default DateTimePickerComponent;
