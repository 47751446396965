import "./SideBar.scss";
import React, { useEffect, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "../../assets/st-app-icon.svg";
import { ReactComponent as DownArrow } from "../../assets/icons/chevron-down.svg";
import { ReactComponent as ToggleSidebarIcon } from "../../assets/icons/toggle-sidebar-icon.svg";
import { ReactComponent as LocationsIcon } from "../../assets/icons/location-icon.svg";
import { ReactComponent as DriverIcon } from "../../assets/icons/drivers-icon.svg";
import { ReactComponent as TruckIcon } from "../../assets/icons/truck.svg";
import { ReactComponent as JobsIcon } from "../../assets/icons/jobs-icon.svg";
import { ReactComponent as TripHistoryIcon } from "../../assets/icons/trip-history-icon.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/profile-icon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings-icon.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard-icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout-icon.svg";
import { useAuth } from "../../contexts/AuthContext";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { APP_VERSION } from "../../version";

interface SideBarProps {
  isSidebarPinned: boolean;
  togglePin: () => void;
}

interface SidebarItem {
  name: string;
  path: string;
  icon: JSX.Element;
  children?: { name: string; path: string }[]; // Child items for accordion-style menus
}

const SideBar: React.FC<SideBarProps> = ({ isSidebarPinned, togglePin }) => {
  const { logout } = useAuth();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [openMenu, setOpenMenu] = useState<string | null>(null);
  const location = useLocation();
  const [forceCollapse, setForceCollapse] = useState(false);

  const mainSidebarItems: SidebarItem[] = [
    // {
    //   name: "Dashboard",
    //   path: "/dashboard",
    //   icon: <DashboardIcon />,
    // },
    {
      name: "Jobs",
      path: "/jobs",
      icon: <JobsIcon />,
      children: [
        { name: "Create New Job", path: "/jobs/create-new" },
        { name: "All Jobs", path: "/jobs/all" },
        { name: "Initiated", path: "/jobs/initiated" },
        { name: "Assigned", path: "/jobs/assigned" },
        { name: "In Progress", path: "/jobs/in-progress" },
        { name: "Completed", path: "/jobs/completed" },
        // { name: "Cancelled", path: "/jobs/cancelled" },
      ],
    },
    // {
    //   name: "Trip History",
    //   path: "/trip-history",
    //   icon: <TripHistoryIcon />,
    // },
    {
      name: "Trips",
      path: "/trips",
      icon: <TripHistoryIcon />,
      children: [
        { name: "Active", path: "/trips/active" },
        { name: "History", path: "/trips/history" },
      ],
    },
    // {
    //   name: "Transporters",
    //   path: "/transporters",
    //   icon: <TruckIcon />,
    // },
    // {
    //   name: "Drivers",
    //   path: "/drivers",
    //   icon: <DriverIcon />,
    // },
    // {
    //   name: "Locations",
    //   path: "/locations",
    //   icon: <LocationsIcon />,
    // },
  ];

  const subSidebarItems: SidebarItem[] = [
    { name: "Profile", path: "/profile", icon: <ProfileIcon /> },
    // { name: "Settings", path: "/settings", icon: <SettingsIcon /> },
  ];

  const handleOpenConfirm = () => setOpenConfirm(true);
  const handleCloseConfirm = () => setOpenConfirm(false);
  const handleConfirmAction = () => {
    logout();
    setOpenConfirm(false);
  };

  const toggleSidebarPin = () => {
    togglePin();
    if (isSidebarPinned) {
      // When unpinning, collapse the sidebar
      setHovered(false);
      setForceCollapse(true); // Temporarily disable hover effects
      setTimeout(() => setForceCollapse(false), 300); // Allow hover after a delay
    }
  };

  const handleHover = (state: boolean) => {
    if (!isSidebarPinned && !forceCollapse) {
      setHovered(state);
    }
  };

  const handleAccordionToggle = (menu: string | null) => {
    setOpenMenu((prev) => (prev === menu ? null : menu));
  };

  const isSectionActive = (path: string, children?: { path: string }[]) => {
    if (location.pathname.startsWith(path)) return true;
    if (children) {
      return children.some((child) => location.pathname.startsWith(child.path));
    }
    return false;
  };

  // Compute active menu only once when location changes
  const activeMenu = useMemo(() => {
    const activeItem = mainSidebarItems.find((item) =>
      isSectionActive(item.path, item.children)
    );
    return activeItem ? activeItem.name : null;
  }, [location.pathname, mainSidebarItems]);

  // Sync `openMenu` with activeMenu
  useEffect(() => {
    setOpenMenu(activeMenu);
  }, [activeMenu]);

  const sidebarWidth = isSidebarPinned || hovered ? "280px" : "80px";

  return (
    <>
      <div
        style={{
          width: sidebarWidth,
          transition: "width 0.3s ease",
          position: isSidebarPinned ? "relative" : "absolute",
          zIndex: isSidebarPinned ? "auto" : 1000,
        }}
        className={`sidebar_style ${
          !isSidebarPinned && hovered ? "overlay" : ""
        }`}
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
      >
        <img
          className={`logo px-[16px] pt-[16px] pb-[35px] max-w-[240.5px] ${
            isSidebarPinned || hovered ? "pinned_logo" : "collapsed_logo"
          }`}
          src={Logo}
          alt="Smart Transport Logo"
          title={`Smart Transport v${APP_VERSION}`}
        />
        {(isSidebarPinned || hovered) && (
          <button
            className="toggle_sidebar_btn"
            onClick={toggleSidebarPin}
            title={
              isSidebarPinned
                ? "Click to Unpin Sidebar"
                : "Click to Pin Sidebar"
            }
          >
            <ToggleSidebarIcon />
          </button>
        )}
        <div className="sidebar_items">
          <nav>
            <ul>
              {mainSidebarItems.map((item) => (
                <li key={item.path}>
                  <div className="sidebar_parent">
                    {item.children ? (
                      <div
                        className={`sidebar_link 
                        ${openMenu === item.name ? "opened_menu" : ""} 
                        ${
                          isSectionActive(item.path, item.children)
                            ? "active"
                            : ""
                        }`}
                        onClick={() => handleAccordionToggle(item.name)}
                      >
                        <div className="sidebar_parent_button">
                          <span className="icon">{item.icon}</span>
                          {(isSidebarPinned || hovered) && (
                            <span className="text">{item.name}</span>
                          )}
                          {(isSidebarPinned || hovered) && (
                            <button
                              className={`accordion_toggle ${
                                openMenu === item.name ? "expanded" : ""
                              }`}
                            >
                              <DownArrow />
                            </button>
                          )}
                        </div>
                      </div>
                    ) : (
                      <NavLink
                        to={item.path}
                        className={({ isActive }) =>
                          isActive || isSectionActive(item.path) ? "active" : ""
                        }
                        onClick={() => handleAccordionToggle(null)}
                      >
                        <span className="icon">{item.icon}</span>
                        {(isSidebarPinned || hovered) && (
                          <span className="text">{item.name}</span>
                        )}
                      </NavLink>
                    )}
                  </div>
                  {(isSidebarPinned || hovered) &&
                    item.children &&
                    openMenu === item.name && (
                      <ul
                        className={`sub_menu ${
                          isSectionActive(item.path, item.children)
                            ? "active"
                            : ""
                        }`}
                      >
                        {item.children.map((child) => (
                          <li key={child.path}>
                            <div className="sidebar_child">
                              <NavLink
                                to={child.path}
                                className={({ isActive }) =>
                                  isActive ? "active" : ""
                                }
                              >
                                {child.name}
                              </NavLink>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                </li>
              ))}
            </ul>
            <div className="divider"></div>

            <ul>
              {subSidebarItems.map((item) => (
                <li key={item.path}>
                  <div className="sidebar_parent">
                    <NavLink
                      to={item.path}
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      <span className="icon" title={item.name}>
                        {item.icon}
                      </span>
                      {(isSidebarPinned || hovered) && (
                        <span className="text">{item.name}</span>
                      )}
                    </NavLink>
                  </div>
                </li>
              ))}
            </ul>
          </nav>

          <button className="logout_btn" onClick={handleOpenConfirm}>
            <span className="icon" title="logout">
              <LogoutIcon />
            </span>
            {(isSidebarPinned || hovered) && <span>Logout</span>}
          </button>
        </div>
      </div>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        onConfirm={handleConfirmAction}
        title="Logout Confirmation"
        message="Are you sure you want to logout?"
        confirmText="Yes"
      />
    </>
  );
};

export default SideBar;
