import React from "react";
import { Switch, FormControlLabel } from "@mui/material";

interface SwitchComponentProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  disabled?: boolean;
  className?: string;
}

const SwitchComponent: React.FC<SwitchComponentProps> = ({
  checked,
  onChange,
  label,
  disabled = false,
  className,
}) => {
  return (
    <FormControlLabel
      className={`select-none m-0 ${className ? className : ""}`}
      control={
        <Switch
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          color="primary"
        />
      }
      label={label || ""}
      labelPlacement="start"
    />
  );
};

export default SwitchComponent;
