export const getPageTitle = (pathname: string): string => {
  switch (pathname) {
    case "/":
      return "Home";
    case "/dashboard":
      return "Dashboard";
    case "/jobs":
      return "Jobs";
    case "/jobs/create-new":
      return "Create New Job";
    case "/jobs/all":
      return "All Jobs";
    case "/jobs/initiated":
      return "Initiated Jobs";
    case "/jobs/assigned":
      return "Assigned Jobs";
    case "/jobs/in-progress":
      return "In Progress Jobs";
    case "/jobs/completed":
      return "Completed Jobs";
    case "/jobs/cancelled":
      return "Cancelled Jobs";
    // case "/trip-history":
    //   return "Trip History";
    case "/trips/active":
      return "Active Trips";
    case "/trips/history":
      return "Trip History";
    case "/transporters":
      return "Transporters";
    case "/drivers":
      return "Drivers";
    case "/locations":
      return "Locations";
    case "/profile":
      return "Profile";
    case "/settings":
      return "Settings";
    default:
      return "";
  }
};

export const getSubPageTitle = (pathname: string): string => {
  if (pathname.includes("/assign-containers")) {
    return "Assign Containers";
  } else if (false) {
    // dummy else if ladder
    return "";
  } else {
    return "";
  }
};
