import React, { useRef, useState, useEffect, useCallback } from "react";
import JobService from "../../services/JobService";
import { JobOrder, TripHistoryStats } from "../../interfaces/job.interface";
import { useLoading } from "../../contexts/LoadingContext";
import moment from "moment";
import SearchBar from "../SearchBar/SearchBar";
import { ReactComponent as Filtericon } from "../../assets/icons/filter-icon.svg";
import { ReactComponent as Downloadicon } from "../../assets/icons/download-icon.svg";
import DateTimePickerComponent from "../DateTimePicker/DateTimePicker";
import Modal from "../Modal/Modal";
import JobDetails from "../Jobs/JobDetails";
import TransporterDetails from "../TransporterDetails";
import ContainerDetails from "../Jobs/ContainerDetails";
import { formatEpochDate } from "../../utils/general";
import "./TripHistoryComponent.scss";
import { useLoader } from "../../contexts/LoaderContext";

export default function TripHistoryComponent() {
  const [jobs, setJobs] = useState<JobOrder[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const loader = useLoading();
  const [pageSize, setPageSize] = useState(50); // Default page size // Number of jobs per page
  const [selectedJob, setSelectedJob] = useState<JobOrder | null>(null);
  const dateTimePickerStartRef = useRef<{ reset: () => void }>(null);
  const dateTimePickerEndRef = useRef<{ reset: () => void }>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showLoader, hideLoader } = useLoader();
  const [tripStats, setTripStats] = useState<TripHistoryStats | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [dateRange, setDateRange] = useState<{
    startDate: number | null;
    endDate: number | null;
  }>({ startDate: null, endDate: null });
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const [isInitialLoading, setIsInitialLoading] = useState(true); // Track initial load
  const formattedNumber = (number: number) => number.toLocaleString();

  const handleMoreClick = (job: any) => {
    setSelectedJob(job);
    setIsModalOpen(true);
  };

  const loadStats = async () => {
    try {
      const response = await JobService.getJobHistoryStats();
      setTripStats(response.data);
    } catch (error) {
      setTripStats(null);
      console.error("Error loading job history stats", error);
    }
  };

  // Function to load jobs with pagination (using "skip" as page offset)
  const loadJobs = useCallback(
    async (page: any) => {
      if (isInitialLoading) {
        showLoader();
      }
      loader.setLoading(true);
      try {
        const response = await JobService.getJobHistory({
          limit: pageSize,
          skip: page - 1,
          jobCode: searchQuery,
          from: dateRange.startDate,
          to: dateRange.endDate,
        });
        const newJobs = response.data.data.content;
        setJobs(newJobs);
        setTotalPages(response.data.data.page.totalPages);
        setTotalElements(response.data.data.page.totalElements);
        // const enrichedData = enrichJobWithDetails(jobData);
        // setEnrichedJobs(enrichedData);
      } catch (error) {
        console.error("Error loading jobs", error);
      } finally {
        loadStats();
        loader.setLoading(false);
        if (isInitialLoading) {
          setTimeout(() => {
            hideLoader(); // Hide loader after the initial load
            setIsInitialLoading(false); // Set the initial load as complete
          }, 1000);
        }
      }
    },
    [pageSize, searchQuery, dateRange, showLoader, hideLoader, isInitialLoading]
  );

  // Load jobs when the component mounts or page changes
  useEffect(() => {
    loadJobs(currentPage);
  }, [currentPage, loadJobs]);

  // Pagination handler
  const handlePageChange = (page: any) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleFilterClick = (values: any) => {
    console.log(
      "Start Date:",
      values.startDate
        ? moment.unix(values.startDate).format("DD-MM-YYYY hh:mm A")
        : "Not selected"
    );
    console.log(
      "End Date:",
      values.endDate
        ? moment.unix(values.endDate).format("DD-MM-YYYY hh:mm A")
        : "Not selected"
    );
  };

  const handleSearch = (query: string) => {
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      setSearchQuery(query);
      setCurrentPage(1); // Reset to the first page on search
    }, 500); // Debounce delay
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1); // Reset to first page on pageSize change
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pageNumbers.push(1, 2, 3, 4, "...", totalPages);
      } else if (currentPage >= totalPages - 2) {
        pageNumbers.push(
          1,
          "...",
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages
        );
      } else {
        pageNumbers.push(
          1,
          "...",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          totalPages
        );
      }
    }

    return (
      <>
        {pageNumbers.map((page, index) => (
          <button
            key={index}
            onClick={() => page !== "..." && handlePageChange(page)}
            className={`min-w-10 px-3 py-2 rounded ${
              currentPage === page
                ? "bg-blueText text-white"
                : "bg-white hover:bg-gray-300 text-gray-700"
            }`}
          >
            {page}
          </button>
        ))}
      </>
    );
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    job: JobOrder
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedJob(job);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // setSelectedJob(null);
  };

  const handleOptionClick = (option: string) => {
    if (selectedJob) {
      // Use the selectedJob state directly here
      if (option === "View Job") {
        console.log("Selected job:", selectedJob); // Logs the specific job
        setIsModalOpen(true);
      } else if (option === "View Track") {
        // Navigate to track details
      } else if (option === "View Transporter") {
        // Show transporter details
      }
    }
    handleMenuClose(); // Close the menu
  };

  // const handleOpenModal = (job: JobOrder) => {

  //  setSelectedJobId(job.jobId)
  //  setSelectedJob(job);

  //   setIsModalOpen(true);
  // };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedJob(null);
    // setSelectedJobId(null);
  };
  const [view, setView] = useState("jobDetails");

  const handleViewChange = (view: string) => {
    setView(view);
  };

  const handleSetStartDate = (newStartDate: number) => {
    setDateRange((prev) => ({
      ...prev,
      startDate: newStartDate,
    }));
  };

  const handleSetEndDate = (newEndDate: number) => {
    setDateRange((prev) => ({
      ...prev,
      endDate: newEndDate,
    }));
  };

  const resetDates = () => {
    setDateRange({
      startDate: null,
      endDate: null,
    });
    if (dateTimePickerStartRef.current) dateTimePickerStartRef.current.reset();
    if (dateTimePickerEndRef.current) dateTimePickerEndRef.current.reset();
  };

  return (
    <div className="p-[20px] trip-history-page">
      <div className="flex justify-between bg-card rounded-lg border border-gray-200">
        <div className="text-left flex-1 p-6 border border-gray-200">
          <span className="block text-sm font-bold text-grayText mb-2">
            Total Jobs
          </span>
          <span
            className="block text-4xl font-bold"
            style={{ color: "#59585c" }}
          >
            {formattedNumber(totalElements) || 0}
          </span>
        </div>
        <div className="text-left flex-1 p-6 border border-gray-200">
          <span className="block text-sm font-bold text-grayText mb-2">
            Total Trips
          </span>
          <span
            className="block text-4xl font-bold"
            style={{ color: "#59585c" }}
          >
            {(tripStats?.trips && formattedNumber(tripStats?.trips)) || 0}
          </span>
        </div>
        <div className="text-left flex-1 p-5 border border-gray-200">
          <span className="block text-sm font-bold text-grayText mb-2">
            Total Containers
          </span>
          <span
            className="block text-4xl font-bold"
            style={{ color: "#59585c" }}
          >
            {(tripStats?.containers &&
              formattedNumber(tripStats?.containers)) ||
              0}
          </span>
        </div>
        <div className="text-left flex-1 p-5 border border-gray-200">
          <span className="block text-sm font-bold text-grayText mb-2">
            20ft
          </span>
          <span
            className="block text-4xl font-bold"
            style={{ color: "#1d3557" }}
          >
            {(tripStats?.count20 && formattedNumber(tripStats?.count20)) || 0}
          </span>
        </div>
        <div className="text-left flex-1 p-5 border border-gray-200">
          <span className="block text-sm font-bold text-grayText mb-2">
            40ft
          </span>
          <span
            className="block text-4xl font-bold"
            style={{ color: "#457b9d" }}
          >
            {(tripStats?.count40 && formattedNumber(tripStats?.count40)) || 0}
          </span>
        </div>
        <div className="text-left flex-1 p-5 border border-gray-200">
          <span className="block text-sm font-bold text-grayText mb-2">
            45ft
          </span>
          <span
            className="block text-4xl font-bold"
            style={{ color: "#69b9bc" }}
          >
            {(tripStats?.count45 && formattedNumber(tripStats?.count45)) || 0}
          </span>
        </div>
      </div>
      <div className="flex justify-between items-center min-h-[120px]">
        <div className="p-2 mt-2">
          <SearchBar
            placeholder="Search by Job Code"
            onSearch={handleSearch}
            showClearButton={true}
          />
        </div>
        <div className="flex items-center space-x-2">
          <div className="flex items-center space-x-2 ">
            <div className="form-group ">
              <label htmlFor="startDate">Start Date</label>
              <DateTimePickerComponent
                skipMinDate={true}
                ref={dateTimePickerStartRef}
                onChange={(date: Date | null) => {
                  console.log(date);
                  if (date) handleSetStartDate(Math.floor(date.getTime()));
                }}
              ></DateTimePickerComponent>
            </div>

            <div className="form-group">
              <label htmlFor="endDate">End Date</label>
              <DateTimePickerComponent
                skipMinDate={true}
                ref={dateTimePickerEndRef}
                onChange={(date: Date | null) => {
                  if (date) handleSetEndDate(Math.floor(date.getTime()));
                }}
              />
            </div>

            <button
              type="button"
              className="secondary_button !mt-[5px] min-w-max"
              onClick={resetDates}
              disabled={
                dateRange.startDate === null && dateRange.endDate === null
              }
            >
              Reset Dates
            </button>

            {/* <button type="submit" className="secondary_button">
                    <div className="flex items-center">
                      <Filtericon className="text-blueText mr-2" />
                      Filter
                    </div>
                  </button> */}
          </div>
          {/* flex items-center bg-white border border-grayText px-4 py-3.5  rounded hover:bg-grayText transition */}
          {/* <button className="secondary_button">
            <div className="flex items-center">
              <Downloadicon className="text-blueText" />
              <span className="whitespace-nowrap ml-2 ">Download Report</span>
            </div>
          </button> */}
        </div>
      </div>
      {jobs.length > 0 && (
        <>
          <div className="overflow-y-auto min-h-[231px]">
            <table className="min-w-full font-semibold bg-white rounded-md">
              <thead className="sticky top-0 bg-background">
                <tr className="bg-background text-grayText">
                  {/* <th className="px-4 text-center">
                <input type="checkbox" />
              </th> */}
                  <th className="px-4 ">Sl. No.</th>
                  <th className="px-4 text-left">Job Code</th>
                  <th className="px-4 text-left">Type</th>
                  <th className="px-4 text-left">Source</th>
                  <th className="px-4 text-left">Destination</th>
                  <th className="px-4 text-left">Start Date</th>
                  <th className="px-4 text-left">End Date</th>
                  <th className="px-4 text-left">Cut Off</th>
                  {/* <th className="px-4 text-left">Movement Cycle</th> */}
                  {/* <th className="px-4 text-left">Status</th> */}
                  {/* <th className="px-4 text-center">More</th> */}
                </tr>
              </thead>
              <tbody>
                {jobs.map((job, index) => (
                  <tr
                    key={job.jobId}
                    className="border-t hover:bg-gray-50 text-transporterText"
                  >
                    {/* <td className="px-4 text-center">
                  <input type="checkbox" />
                </td> */}
                    <td className="px-4 text-center">
                      {(currentPage - 1) * pageSize + index + 1}
                    </td>
                    <td
                      className="px-4 py-2  underline hover:text-blueText cursor-pointer"
                      onClick={() => handleMoreClick(job)}
                    >
                      {job.jobCode}
                    </td>
                    {/* <td className="px-4 ">{job.cycle}</td> */}
                    <td className="px-4 ">{job.jobType}</td>
                    <td className="px-4 ">{job.source}</td>
                    <td className="px-4 ">{job.destination}</td>
                    {/* <td className="px-4 ">{job.jobStatus}</td> */}
                    <td className="px-4">
                      {job.startDate ? formatEpochDate(job.startDate) : "N/A"}
                    </td>
                    <td className="px-4">
                      {job.endDate ? formatEpochDate(job.endDate) : "N/A"}
                    </td>
                    <td className="px-4">
                      {formatEpochDate(job.gateCloseDate)}
                    </td>
                    {/* <td className="px-4 text-center">
                  <button
                    className="bg-gray-400 text-white px-2 rounded"
                    onClick={() => handleMoreClick(job)}
                  >
                    ...
                  </button>
                </td> */}
                    {/* <td className="px-4 text-center">
                  <div
                    onClick={(event) => handleMenuClick(event, job)}
                    className="cursor-pointer"
                  >
                    <MoreVertIcon />
                  </div>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleOptionClick("View Job")}>
                      View Job
                    </MenuItem>
                    <MenuItem onClick={() => handleOptionClick("View Track")}>
                      View Track
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleOptionClick("View Transporter")}
                    >
                      View Transporter
                    </MenuItem>
                  </Menu>
                </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex flex-col items-center mt-4 space-y-4">
            <div className="flex justify-end items-center space-x-2 w-full">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`px-4 py-2 rounded ${
                  currentPage === 1
                    ? "bg-white text-gray-500 cursor-not-allowed"
                    : "bg-white hover:bg-gray-300 text-gray-700"
                }`}
              >
                Previous
              </button>
              {renderPageNumbers()}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={`px-4 py-2 rounded ${
                  currentPage === totalPages
                    ? "bg-white text-gray-500 cursor-not-allowed"
                    : "bg-white hover:bg-gray-300 text-gray-700"
                }`}
              >
                Next
              </button>
              <select
                value={pageSize}
                onChange={handlePageSizeChange}
                className="ml-4 border rounded p-2"
              >
                {/* <option value={2}>2</option> */}
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
        </>
      )}
      {jobs.length === 0 && (
        <>
          <div className="center_floater_container max-h-[231px] text-[20px]">
            No results !
          </div>
        </>
      )}
      {isModalOpen && selectedJob && (
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          title={`#${selectedJob.jobCode}`}
          className="job_preview_modal"
        >
          <div className="bg-card rounded-[10px] selected-job-details card-shadow">
            <div className="flex px-[20px] border-b border-bottomline justify-between">
              <div className="flex">
                <button
                  className={`mr-2 py-2 px-4 font-w-[500] ${
                    view === "jobDetails"
                      ? "border-b border-blueText text-blueText"
                      : "text-notSelected"
                  }`}
                  onClick={() => handleViewChange("jobDetails")}
                >
                  Job Details
                </button>
                <button
                  className={`py-2 px-4 font-w-[500] ${
                    view === "containerDetails"
                      ? "border-b border-blueText text-blueText"
                      : "text-notSelected"
                  }`}
                  onClick={() => handleViewChange("containerDetails")}
                >
                  Containers
                </button>
                <button
                  className={`py-2 px-4 font-w-[500] ${
                    view === "transporterDetails"
                      ? "border-b border-blueText text-blueText"
                      : "text-notSelected"
                  }`}
                  onClick={() => handleViewChange("transporterDetails")}
                >
                  Transporters
                </button>
              </div>
            </div>
            {view === "jobDetails" && <JobDetails job={selectedJob} />}
            {view === "containerDetails" && (
              <ContainerDetails
                job={selectedJob}
                hideContainerAssignment={true}
              ></ContainerDetails>
            )}
            {view === "transporterDetails" && (
              <TransporterDetails jobOrder={selectedJob} />
            )}
          </div>
        </Modal>
      )}
    </div>
  );
}
