import { useEffect, useState } from "react";

const VersionChecker = () => {
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [latestVersion, setLatestVersion] = useState("");

  useEffect(() => {
    const checkForUpdate = async () => {
      try {
        const response = await fetch(`/meta.json?v=${Date.now()}`, {
          cache: "no-cache",
        });

        // Check if the response is JSON
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new Error("Invalid JSON response");
        }

        const data = await response.json();
        const latestVersion = data.version;
        setLatestVersion(latestVersion);

        const storedVersion = localStorage.getItem("appVersion");
        if (storedVersion && storedVersion !== latestVersion) {
          setUpdateAvailable(true);
        } else {
          localStorage.setItem("appVersion", latestVersion);
        }
      } catch (error) {
        console.error("Error checking app version:", error);
      }
    };

    checkForUpdate();
  }, []);

  const handleReload = () => {
    window.location.href =
      window.location.origin + "?v=" + new Date().getTime();
    setTimeout(() => {
      localStorage.setItem("appVersion", latestVersion);
    });
  };

  return updateAvailable ? (
    <div className="fixed z-50 bottom-4 right-4 bg-[#fffff6] text-[#027cb5] px-4 py-2 rounded-lg shadow-lg text-center border border-[#027cb5] border-x-1">
      <p>A new version is available</p>
      <button
        onClick={handleReload}
        className="bg-blueText text-white mt-2 px-2 py-1 rounded"
      >
        Refresh
      </button>
    </div>
  ) : null;
};

export default VersionChecker;
