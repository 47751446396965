import { Outlet } from "react-router-dom";
import SideBar from "../components/SideBar/SideBar";
import Header from "../components/Header/Header";
import { useEffect, useState } from "react";

const SidebarLayout: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isPinned, setIsPinned] = useState<boolean>(() =>
    JSON.parse(localStorage.getItem("isSidebarPinned") || "true")
  );

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const togglePin = () => {
    setIsPinned((prev) => {
      const newValue = !prev;
      localStorage.setItem("isSidebarPinned", JSON.stringify(newValue));
      return newValue;
    });
  };

  return (
    <div style={{ ...styles.layoutContainer }}>
      <SideBar isSidebarPinned={isPinned} togglePin={togglePin} />
      <div
        style={{
          ...styles.content,
          marginLeft: !isPinned ? 80 : 0,
        }}
      >
        <Header onSearchQueryChange={handleSearch} />
        <div className="main_area_inset_shadow">
          <Outlet
            context={{
              searchQuery,
              setSearchQuery: handleSearch,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  layoutContainer: {
    display: "flex",
  },
  content: {
    flex: 1,
  },
};

export default SidebarLayout;
